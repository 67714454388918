//
// your custom scss goes here
//
::-moz-selection {
    /* Code for Firefox */
    color: $white;
    background: $primary;
}

 ::selection {
    color: $white;
    background: $primary;
}

.media,
.border-radius-15 {
    border-radius: 15px;
}

h1,
h2 {
    font-family: "DM Serif Display", serif;
}

#landingvideo {
    box-shadow: 0px 20px 30px -15px rgba(0, 0, 0, 0.4);
}

.pointseven {
    height: 92vh;
}

.fullheight {
    height: 100vh;
}

.navbar .navbar-brand img {
    height: 55px;
}

.testimoniallogo img {
    max-height: 50px;
    max-width: auto;
    width: auto;
    filter: grayscale(1);
    transition: filter 100ms linear;
}

.testimoniallogo img:hover {
    filter: grayscale(0);
}

.testimoniallogo2 {
    display: block;
    height: 100%;
    max-height: 50px;
    margin: auto;
}

.ctarow {
    display: inline-block;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.ctarow :nth-child(1) {
    flex-grow: .9;
    text-align: right;
}

.ctarow :nth-child(2) {
    flex-grow: 1.2;
}

.ctarow :nth-child(3) {
    flex-grow: .9;
}

.ctaportrait {
    display: block;
    width: 100%;
    min-width: 200px;
}

.cta-content {
    z-index: 0;
}

.arrow2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    overflow: hidden;
}

.minus-margin {
    margin: 0px -5rem;
}

.videolink {
    cursor: pointer;
}

body {
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

 ::-webkit-scrollbar {
    background: transparent;
}

 ::-webkit-scrollbar-track {
    background: none;
}

 ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 100vw;
    background-color: $gray-300;
}

 ::-webkit-scrollbar-thumb:hover {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 100vw;
    background-color: $gray-400;
}

.portfoliogrid {
    background-color: $dark;
}

.videoquote {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.videoquote:hover {
    color: $primary;
}

@media screen and (max-width: 992px) {
    .minus-margin {
        margin: 0px 0px;
    }
}

@media screen and (max-width: 768px) {
    .cta {
        padding-bottom: 5rem;
    }
    .ctarow div {
        text-align: left;
    }
    .ctarow div {
        width: 50%;
    }
    .ctarow :nth-child(1) {
        text-align: left;
    }
    .ctarow :nth-child(2) {
        flex-grow: 0;
    }
    .ctarow :nth-child(2) :nth-child(1) {
        display: none;
    }
    .ctarow .align-btn {
        text-align: left;
    }
     ::-webkit-scrollbar {
        display: none;
    }
    .portfoliogrid {
        background-color: $white;
        margin-bottom: 1rem;
    }
    .portfoliogrid div a {
        border-radius: 10px;
    }
}

// Custom Spacing
.cta-title {
    padding-right: .5rem;
}
